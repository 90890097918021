<i18n lang="yaml">
ru:
  all_products: 'Показать все товары'
  all: 'Все'
  category_view: 'Обзор'
  catalog_wellfar: 'Каталог продукции WELLFAR'
  commercial_vehicle_parts: 'Детали коммерческих автомобилей'
  passenger_vehicle_parts: 'Детали легковых автомобилей'
en:
  all_products: 'View all products'
  all: 'All'
  category_view: 'Overview'
  catalog_wellfar: 'WELLFAR Product Catalog'
  commercial_vehicle_parts: 'Commercial vehicle parts'
  passenger_vehicle_parts: 'Passenger car parts'
</i18n>

<template>
  <transition name="fade">
  <div v-if="rootCategory" class="tree">
    <div class="container tree-desktop">
      <div class="close-tree">
        <a href="#" class="circle" @click.prevent="closeCat">
          <i class="svg-icon cross"></i>
        </a>
      </div>
      <div class="catalog-header">
          <div class="h1">{{ getCategoryLocaleData(rootCategory).name }}</div>
          <nuxt-link v-if="!isAllProdsPath" :to="localePath('/catalog/')" @click.native="closeCat">{{
              $t('all_products')
            }}
          </nuxt-link>
          <span v-else @click="closeCat">{{ $t('all_products') }}</span>
      </div>

      <div class="square-items">
          <div class="catalog-square">
            <template v-for="sub1 in rootCategory.subcategories">
              <div class="catalog-square-item" @mouseenter="action = sub1.id">
                <div class="catalog-square-item-container" @click="closeCat">
                  <nuxt-link class="cat-square-item-header"
                             :to="localePath(`/${rootCategory.slug}/${sub1.slug}/`)">{{
                      getCategoryLocaleData(sub1).name
                    }}
                  </nuxt-link>
                </div>
              </div>
            </template>

            <nuxt-link class="favourites" v-show="favouriteProductsExists" :to="localePath('/products/favourites/')"
                       :aria-label="$t('favourites')">
              <i class="svg-icon star icon-brand"></i><span @click="closeCat">{{ $t('favourites') }}</span>
            </nuxt-link>

            <div v-if="contacts" class="contacts">
              <span v-if="contacts.phone">Тел. {{ contacts.phone }}</span>
              <span v-if="contacts.email">Email: {{ contacts.email }}</span>
            </div>
          </div>
        <div class="catalog-rendered-items">
          <template v-for="sub1 in rootCategory.subcategories">
            <div class="rendered_value" v-show="action === sub1.id">
              <div class="name-category">
                <div class="h3">{{ getCategoryLocaleData(sub1).name }}</div>
                <nuxt-link v-if="sub1.subcategories.length > 1"
                           :to="localePath(`/${rootCategory.slug}/${sub1.slug}/`)" @click.native="closeCat">
                  {{ $t('all') }}
                  {{
                    (getCategoryLocaleData(sub1).name_plural || (getCategoryLocaleData(sub1).name) || '').toLowerCase()
                  }}
                </nuxt-link>
                <nuxt-link :to="localePath(`/${rootCategory.slug}/${sub1.slug}/overview`)" @click.native="closeCat">
                  {{ $t('category_view') }}
                </nuxt-link>
              </div>
              <div class="pre-items">
                <div class="items">
                  <template v-for="sub2 in sub1.subcategories">
                            <span class="catalog-square-item__link" @click="closeCat">
                              <nuxt-link
                                  :to="localePath(`/${rootCategory.slug}/${sub1.slug}/${sub2.slug}/`)">
                                {{ getCategoryLocaleData(sub2).name }}
                              </nuxt-link>
                            </span>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <TreeMobile :categories="categories" @toggle-cat="closeCat"/>
  </div>
  </transition>
</template>

<script>
import {Category} from '@/models/category';

import ProductsSearchInput from '@/components/catalog/products-search-input';
import {DbConfig} from "@/models/db-config";
import {SeoHeaders} from "@/models/seo";
import {Filters} from "@/models/filters";

export default {
  components: {
    ProductsSearchInput,
    'TreeMobile': () => import(`@/components/${process.env.VENDOR}/tree-mobile`),
  },
  data() {
    return {
      wellfar: 'wellfar_',
      categories: [],
      action: null,
      slideTransition: null,
      dbConfig: {},
      activeFilter: null,
      seoHeaders: [],
      filters: []
    }
  },
  watch: {
    showChild(value) {
      if (value) {
        this.setSlideTransition('slide-right');
      } else {
        this.setSlideTransition('slide-left');
      }
    },
    '$i18n.locale': {
      handler(value) {
        Category.config({count_loading: true}).get().then((response) => this.categories = response);
      },
    },
  },
  computed: {
    vendor() {
      return process.env.VENDOR
    },
    rootCategory() {
      if (this.categories[0] && this.categories[0].subcategories && this.categories[0].subcategories.length) {
        this.action = this.categories[0].subcategories[0].id
      }
      return this.categories[0];
    },
    isAllProdsPath() {
      return this.$route.path === this.localePath('/catalog/') + '/';
    },
    favouriteProductsExists() {
      return this.$auth.loggedIn && this.$store.getters.favouriteProductsExists;
    },
    contacts() {
      return {
        'phone': this.dbConfig.company_phone,
        'email': this.dbConfig.company_email
      }
    }
  },
  async fetch() {
    [this.dbConfig, this.categories] = await Promise.all([
      DbConfig.config({count_loading: true}).first(),
      Category.config({count_loading: true}).get(),
    ]);
  },
  methods: {
    getCategoriesFilter(slug) {
      Category.config({count_loading: true}).params({filter: slug}).get().then((response) => this.categories =
          response);
      this.activeFilter = slug;
    },
    getCategoryLocaleData(category) {
      return category.category_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    getCategoryLocaleDataSeoFilter(category, filter_slug) {
      const seoHeader = this.seoHeaders?.find(x => x.category.id === category.id && x.filter.slug === filter_slug) ||
          {};
      if (Object.keys(seoHeader).length) {
        return seoHeader.header_translations?.find(x => x.language.slug === this.$i18n.locale).header || {};
      } else {
         return category.category_translations?.find(x => x.language.slug === this.$i18n.locale).name || {};
      }
    },
    closeCat() {
      this.$emit('toggle-cat');
    },
    setFilter(propId, value) {
      this.closeCat();
      const queryParams = {};
      queryParams[`p${propId}`] = value;
      this.$router.push({
        path: this.localePath('/search/'),
        query: queryParams,
      });
    },
    setSlideTransition(slideDirection) {
      setTimeout(() => {
        this.slideTransition = slideDirection;
      }, 500);
    },
  }
}
</script>
